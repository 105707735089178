import React, { useState } from "react";
import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";

const Testimonial = () => {
  const [swiper, setSwiper] = useState(null);

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };

  return (
    <section className="testimonials-one" id="opinie">
      <div className="container">
        <div className="block-title text-center">
          <h2 className="block-title__title">
            Co mówią o nas <span>Klienci</span> <br />
          </h2>
        </div>
        <div className="testimonials-one__carousel-outer">
          <div className="testimonials-one__carousel">
            <Swiper getSwiper={setSwiper}>
              <div className="item">
                <div className="testimonials-one__single">
                  <div className="testimonials-one__inner">
                    <p>
                    Pełen profesjonalizm!
                    Pierwszy raz to mnie ktoś poganiał  <br />żeby szybciej wykonać dla mnie zlecenie, a nie odwrotnie! <br />
                    Zdecydowanie polecam korzystanie z usług KODiTO! 
                    </p>
                    <h3>BIO-TECH PLAST</h3>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="testimonials-one__single">
                  <div className="testimonials-one__inner">
                    <p>
                    Skorzystanie z usług Tomasza było świetnym wyborem. Współpraca była terminowa, kontakt świetny, a pomoc nieoceniona. Stworzona strona charakteryzuje się dużą funkcjonalnością i estetyką, jest bardzo czytelna, czemu zawdzięczam chętne poruszanie się po niej moich klientów. Wiele osób zachwyca się jej wyglądem, wiem, że przy tak przygotowanej stronie moje usługi uznawane są za jeszcze bardziej profesjonalne i coraz częściej ludzie trafiają do mnie nie przez media społecznościowe, tylko zachęceni nowym wizerunkiem strony internetowej. 
                    </p>
                    <h3>Pora na banana Marta Kłopocka</h3>
                  </div>
                </div>
              </div>
              
            </Swiper>
          </div>
          <div className="testimonials-one__carousel__shape-one"></div>
          <div className="testimonials-one__carousel__shape-two"></div>
          <div className="testimonials-one__nav">
            <div
              onClick={goPrev}
              onKeyDown={goPrev}
              role="button"
              tabIndex="-1"
              className="testimonials-one__nav-left"
            >
              <i className="dimon-icon-left-arrow"></i>
            </div>
            <div
              onClick={goNext}
              onKeyDown={goNext}
              role="button"
              tabIndex="-1"
              className="testimonials-one__nav-right"
            >
              <i className="dimon-icon-right-arrow"></i>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Testimonial;
