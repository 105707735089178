import React from "react";
import BannerMoc from "../assets/images/banner/banner.png";
const Banner = () => {
  return (
    <section className="banner-one" id="banner">
      <span className="banner-one__shape-1"></span>
      <span className="banner-one__shape-2"></span>
      <span className="banner-one__shape-3"></span>
      <span className="banner-one__shape-4"></span>
      <div className="container">
        <div className="banner-one__moc">
          <img src={BannerMoc} alt="Kodito - baner" />
        </div>
        <div className="row">
          <div className="col-xl-6 col-lg-8">
            <div className="banner-one__content">
              <h3 className="banner-one__title">
                Po prostu <br />
                Ma być<br /> <span>pięknie</span>.
              </h3>
              <p className="banner-one__text">
                Od początku stawiamy na estetykę strony oraz jej czytelność.<br />
                Wiemy, że przyciąga to uwagę odbiorcy, co przekłada się również na konwersje.
              </p>
              <a href="#realizacje" className="banner-one__btn thm-btn ">
                <span>Sprawdź realizacje</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Banner;
