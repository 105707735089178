import React from "react";

const Subscribe = () => {
  return (
    <section className="mailchimp-one" id="napisz-do-nas">
      <div className="container">
        <div className="block-title text-center">
          <h2 className="block-title__title">
            Napisz <span> do nas</span> 
          </h2>
        </div>
       <div>
          <a href="mailto:biuro@kodito.pl" rel="nofollow" className="h3">
            <h3>biuro@kodito.pl</h3>
          </a>
         </div>
      
      </div>
    </section>
  );
};
export default Subscribe;
