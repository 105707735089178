import React, { Component } from "react";
import darkLogo from "../assets/images/resources/logo.png";
export default class Blog extends Component {
  constructor() {
    super();
    this.state = {
      scrollBtn: false
    };
    this.handleScroll = this.handleScroll.bind(this);
    this.scrollTop = this.scrollTop.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    if (window.scrollY > 70) {
      this.setState({
        scrollBtn: true
      });
    } else if (window.scrollY < 70) {
      this.setState({
        scrollBtn: false
      });
    }
  }

  scrollTop = () => {
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <div>
        <footer className="site-footer">
          <div className="site-footer__upper">
            <div className="container">
              <div className="row">
                <div className="col-lg-3">
                  <div className="footer-widget footer-widget__about">
                    <img
                      src={darkLogo}
                      width="180"
                      alt="Logo Kodito"
                      title="Logo Kodito"
                      className="footer-widget__logo"
                    />

                    <p className="footer-widget__contact">
                      <a href="mailto:biuro@kodito.pl">
                        biuro@kodito.pl
                      </a>
                    </p>
                  </div>
                </div>
                <div className="col-lg-7 d-flex justify-content-center footer-widget__links-wrap">
                  <div className="footer-widget">
                    <ul className="footer-widget__links list-unstyled">
                    <li className="current scrollToLink">
                      <a href="/">Start</a>
                    </li>
                    <li>
                      <a href="#wymarzona-strona">Wymarzona strona</a>
                    </li>
                    <li>
                      <a href="#ecommerce">Ecommerce</a>
                    </li>
                    <li>
                      <a href="#opinie">Opinie</a>
                    </li>
                    <li>
                      <a href="#realizacje">Realizacje</a>
                    </li>
                    </ul>
                  </div>
                
                </div>
                <div className="col-lg-2">
                  <div className="footer-widget">
                    <div className="site-footer__social">
                      <a
                        href="#s-link"
                        aria-label="social icon"
                        aria-hidden="true"
                        target="_blank"
                        rel="nofollow "
                      >
                        <i className="fa fa-facebook-square"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="site-footer__bottom">
            <div className="container">
              <div className="inner-container text-center">
                <p className="site-footer__copy">
                  &copy; copyright {new Date().getFullYear()} by{" "}
                  <a href="/">Kodito.pl</a>
                </p>
              </div>
            </div>
          </div>
        </footer>

        <div
          onClick={this.scrollTop}
          onKeyDown={this.scrollTop}
          role="button"
          tabIndex="0"
          className="scroll-to-target scroll-to-top"
          style={{ display: this.state.scrollBtn ? "block" : "none" }}
        >
          <i className="fa fa-angle-up"></i>
        </div>
      </div>
    );
  }
}
