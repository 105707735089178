import React from "react";

const Features = () => {
  return (
    <section className="service-one">
      <div className="container">
        <div className="block-title text-center">
          <h2 className="block-title__title">
            Stawiamy na <span>Jakość</span> <br /> Nie <s>jakoś</s>.
          </h2>
        </div>
        <div className="row">
          <div
            className="col-lg-3 col-md-6 col-sm-12 wow fadeInUp"
            data-wow-duration="1500ms"
          >
            <div className="service-one__single text-center">
              <div className="service-one__inner">
                <i className="service-one__icon dimon-icon-laptop"></i>
                <h3>
                  
                    Szybkie <br /> ładowanie
                 
                </h3>
                <p>
                  Wczytywanie strony <br />w trzy sekundy ? <br /> Z nami to możliwe 
                </p>
                
              </div>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-6 col-sm-12 wow fadeInDown"
            data-wow-duration="1500ms"
          >
            <div className="service-one__single text-center">
              <div className="service-one__inner">
                <i className="service-one__icon dimon-icon-presentation"></i>
                <h3>
                  
                    Pełny <br /> Monitoring
                 
                </h3>
                <p>
                  Masz wgląd na to <br /> kto, skąd i gdzie <br />trafia na Twoją stronę
                </p>
                
              </div>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-6 col-sm-12 wow fadeInUp"
            data-wow-duration="1500ms"
          >
            <div className="service-one__single text-center">
              <div className="service-one__inner">
                <i className="service-one__icon dimon-icon-target"></i>
                <h3>
                  
                    Gotowe pod <br /> Pozycjonowanie
                 
                </h3>
                <p>
                  Strona przygotowana jest <br /> pod wyszukiwarkę Google <br />i inne
                </p>
                
              </div>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-6 col-sm-12 wow fadeInDown"
            data-wow-duration="1500ms"
          >
            <div className="service-one__single text-center">
              <div className="service-one__inner">
                <i className="service-one__icon dimon-icon-visualization"></i>
                <h3>
                  
                    Szybka pomoc <br /> nawet po roku
                 
                </h3>
                <p>
                  Pomożemy Ci nawet <br /> po zakończonej <br /> usłudze.
                </p>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Features;
