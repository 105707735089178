import React from "react";
import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";
import brandImgSroka from "../assets/images/brands/sroka.png";
import brandImgPoranabanana from "../assets/images/brands/poranabanana.png";
import brandImgBiotech from "../assets/images/brands/biotech.png";
import brandImgAliatan from "../assets/images/brands/aliatan.png";
import brandImgOndeclinic from "../assets/images/brands/ondeclinic.png";
import brandImgOlejeodfranciszka from "../assets/images/brands/olejeodfranciszka.png";
const Brands = () => {
  const params = {
    slidesPerView: 5,
    loop: true,
    speed: 1000,
    spaceBetween: 30,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },
    // Responsive breakpoints
    breakpoints: {
      1499: {
        slidesPerView: 5
      },

      991: {
        slidesPerView: 3
      },

      767: {
        slidesPerView: 3
      },

      575: {
        slidesPerView: 2
      }
    }
  };

  return (
    <div className="brand-one">
      <div className="container">
        <div className="brand-one__carousel owl-carousel owl-theme">
          <Swiper {...params}>
            <div className="item">
              <img src={brandImgSroka} alt="Sroka fotografia i film" title="Sroka fotografia i film"/>
            </div>
            <div className="item">
              <img src={brandImgPoranabanana} alt="Poranabanana Marta Kłopocka" title="Poranabanana Marta Kłopocka" />
            </div>
            <div className="item">
              <img src={brandImgBiotech} alt="BioTechPlast" title="BioTechPlast"/>
            </div>
            <div className="item">
              <img src={brandImgAliatan} alt="Aliatan" title="Aliatan"/>
            </div>
            <div className="item">
              <img src={brandImgOndeclinic} alt="OndeClinic" title="OndeClinic"/>
            </div>
            <div className="item">
              <img src={brandImgOlejeodfranciszka} alt="Oleje od Franciszka" title="Oleje od Franciszka"/>
            </div>
          </Swiper>
        </div>
      </div>
    </div>
  );
};
export default Brands;
