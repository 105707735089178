import React from "react";
import mocImage from "../assets/images/mocs/cta-moc-2-1.png";
const CallToActionTwo = () => {
  return (
    <section className="cta-two" id="ecommerce">
      <div className="container">
        <img src={mocImage} className="cta-two__moc" alt="alter text" />
        <div className="row">
          <div className="col-lg-6">
            <div className="cta-two__content">
              <i className="cta-two__icon dimon-icon-data"></i>
              <div className="block-title text-left">
                <h2 className="block-title__title">
                  Poznaj naszą <span>całą ofertę</span> <br />
                </h2>
              </div>
              <div className="cta-two__text">
                <p>
                  Specjalizujemy się nie tylko w tworzeniu samych stron insternetowych,
                  ale także tworzymy sklepy internetowej na różnych platformach sprzedażowych, tj.
                  <strong>Shoper, IAI Shop, Woocommerce </strong> 
                </p>
               
              </div>
              <a href="#napisz-do-nas" className="thm-btn">
                <span>Napisz do nas</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default CallToActionTwo;
