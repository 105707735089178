import React from "react";
import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";
import poranabanana from "../assets/images/sites/poranabanana.jpg";
import aliatan from "../assets/images/sites/aliatan.jpg";
import ondeclinic from "../assets/images/sites/ondeclinic.jpg";
import biotechplast from "../assets/images/sites/biotechplast.jpg";
const Screenshots = () => {
  const params = {
    slidesPerView: 1,
    loop: true,
    speed: 1000,
    spaceBetween: 30,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },
    // Responsive breakpoints
    breakpoints: {
      1499: {
        slidesPerView: 2
      },

      991: {
        slidesPerView: 2
      },

      767: {
        slidesPerView: 1
      },

      575: {
        slidesPerView: 1
      }
    }
  };

  return (
    <section className="app-shot-one" id="realizacje">
     
      <div className="container-fluid">
        <div className="block-title text-center">
          <h2 className="block-title__title">
            Wybrane realizacje <br /> <span>stworzone</span> przez nas
          </h2>
        </div>
        <div className="app-shot-one__carousel">
          <Swiper {...params}>
            <div className="item">
              <a href="https://poranabanana.pl" target="_blank" rel="nofollow noreferrer">
                <img src={poranabanana} alt="Realizacja strony Pora na banana" title="Realizacja strony Pora na banana" />
              </a>
            </div>
            <div className="item">
              <a href="https://bofaliatan.pl" target="_blank" rel="nofollow noreferrer">
                <img src={aliatan} alt="Realizacja strony Aliatan" title="Realizacja strony Aliatan" />
              </a>
            </div>
            <div className="item">
              <a href="https://ondeclinic.pl" target="_blank" rel="nofollow noreferrer">
                <img src={ondeclinic} alt="Realizacja strony OndeClinic" title="Realizacja strony OndeClinic" />
              </a>
            </div>
            <div className="item">
              <a href="https://biotechplast.pl" target="_blank" rel="nofollow noreferrer">
                <img src={biotechplast} alt="Realizacja strony BioTechPlast" title="Realizacja strony BioTechPlast" />
              </a>
            </div>
          </Swiper>
        </div>
      </div>
    </section>
  );
};
export default Screenshots;
