import React from "react";
import AccordionItem from "./AccordionItem";
const Faq = () => {
  return (
    <section className="faq-one">
      <div className="container">
        <div className="block-title text-center">
          <h2 className="block-title__title">
            O co <span>najczęściej</span> <br /> pytają Klienci?
          </h2>
        </div>
        <div className="accrodion-grp faq-accrodion">
         
          <AccordionItem
            title="Co potrzebuję do założenia strony internetowej ?"
            content="Do działania strony potrzebny jest serwer ,domena oraz powinien być certyfikat SSL. Nie przejmuj się ,jeżeli nie wiesz jak je kupić. Pomożemy!"
            status={true}
          />
          <AccordionItem
            title="Jaki jest czas realizacji tworzenia strony internetowej ?"
            content="Ciężko jest jednoznacznie odpowiedzieć na to pytanie. Wszystko zależy od zaawansowania strony, od czasu dostarczenia materiałów i wiele innych. Jeżeli strona jest prosta, to może zostać stworzona nawet w 7 dni. "
            status={false}
          />
          
        </div>
      </div>
    </section>
  );
};
export default Faq;
