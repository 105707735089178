import React from "react";
import bgImage from "../assets/images/background/cta-one-bg.png";
import mocImage from "../assets/images/banner/seoPage.png";
const CallToAction = () => {
  return (
    <section className="cta-one" id="wymarzona-strona">
      <img src={bgImage} className="cta-one__bg" alt="alter text" />
      <div className="container">
        <img src={mocImage} className="cta-one__moc" alt="alter text" />
        <div className="row justify-content-lg-end">
          <div className="col-lg-6">
            <div className="cta-one__content">
              <i className="cta-one__icon dimon-icon-data1"></i>
              <div className="block-title text-left">
                <h2 className="block-title__title">
                  Twoja <strong>wymarzona strona</strong> <br />
                  na wyciągniecie ręki
                </h2>
              </div>
              <div className="cta-one__text">
                <p>
                  
                </p>
              </div>
              <ul className="list-unstyled">
                <li>
                  <i className="fa fa-check"></i>Indywidualny projekt graficzny
                </li>
                <li>
                  <i className="fa fa-check"></i>Pracujemy do zadowolenia klienta - nic nie płacisz za poprawki
                </li>
                <li>
                  <i className="fa fa-check"></i>Strona wykonana jest w najnowszych technologiach
                </li>
              </ul>
              <a href="#napisz-do-nas" className="thm-btn">
                <span>Dowiedz się więcej</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default CallToAction;
